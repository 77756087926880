import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http'; // <- http client para las peticiones
import { FormsModule } from '@angular/forms';
import { AngularFontAwesomeModule } from 'angular-font-awesome'; // <-iconos font awesome
import { NgbModule} from '@ng-bootstrap/ng-bootstrap'; // ng boostrap
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // <-animaciones de ng toast
import { NgxIziToastModule } from 'ngx-izitoast'; // <--ng toast
import { StoreModule } from '@ngrx/store'; // <-redux
import { StoreDevtoolsModule } from '@ngrx/store-devtools'; // <-redux
import { AgmCoreModule } from  '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { RecaptchaModule } from 'ng-recaptcha'; // <- libreria de recaptcha

import { PdfViewerModule } from 'ng2-pdf-viewer';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './views/home/home.component';
import { ContactoComponent } from './views/contacto/contacto.component';
import { VacantesComponent } from './views/vacantes/vacantes.component';
import { CafeComponent } from './views/cafe/cafe.component';
import { SucursalComponent } from './views/sucursal/sucursal.component';
import { FacturaComponent } from './views/factura/factura.component';
import { BlogComponent } from './views/blog/blog.component';
import { ProveedorComponent } from './views/proveedor/proveedor.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { FooterComponent } from './components/footer/footer.component';
import { MenuComponent } from './views/menu/menu.component';
import {environment} from '../environments/environment';
import {
  modifiersReducer,
  ordenesReducer,
  sucursalReducer,
  sucursalReducer2,
  sucursalReducer3,
  typeOrderReducer
} from '../redux/ordenes.reducer';
import { OrderDetailsComponent } from './components/order-details/order-details.component';
import { OrderModifiersComponent } from './components/order-modifiers/order-modifiers.component';
import { ModifiersComponent } from './views/modifiers/modifiers.component';
import { MenuToGoComponent } from './views/menu-to-go/menu-to-go.component';
import { SelectSucursalComponent } from './components/select-sucursal/select-sucursal.component';

import { HistoriaComponent } from './views/historia/historia.component';
import { VisionComponent } from './views/vision/vision.component';
import { ValoresComponent } from './views/valores/valores.component';
import { MisionComponent } from './views/mision/mision.component';
import { MiOrdenComponent } from './views/mi-orden/mi-orden.component';
import { ProductsCatComponent } from './components/products-cat/products-cat.component';
import { PromocionesYPaquetesComponent } from './views/promociones-y-paquetes/promociones-y-paquetes.component';
import { MenuredirectComponent } from './components/menuredirect/menuredirect.component';
import { NotFoundComponent } from './views/not-found/not-found.component';
import { EventoComponent } from './views/evento/evento.component';
import { QrRestauranteComponent } from './views/qr-restaurante/qr-restaurante.component';
import { QrDesayunosComponent } from './views/qr-desayunos/qr-desayunos.component';
import { QrComidasycenasComponent } from './views/qr-comidasycenas/qr-comidasycenas.component';
import { QrToGoComponent } from './views/qr-to-go/qr-to-go.component';
import { QrSdComponent } from './views/qr-sd/qr-sd.component';
import { CoberturaADomicilioComponent } from './views/cobertura-a-domicilio/cobertura-a-domicilio.component';
import { MenuQrComponent } from './components/menu-qr/menu-qr.component';
import { EventoDosComponent } from './views/evento-dos/evento-dos.component';
import { WidgetComponent } from './components/widget/widget.component';
import { ReservacionesComponent } from './views/reservaciones/reservaciones.component';
import { ReservacionOpenTableComponent } from './components/reservacion-open-table/reservacion-open-table.component';
import { WidgetResponsiveComponent } from './components/widget-responsive/widget-responsive.component';
import { MexicanisimoComponent } from './views/mexicanisimo/mexicanisimo.component';
import { CampanaComponent } from './views/campana/campana.component';
import { MenuCarbonComponent } from './views/menu-carbon/menu-carbon.component';
import { CuaresmaComponent } from './views/campaigns/cuaresma/cuaresma.component';
import { SeptiembreComponent } from './views/campaigns/septiembre/septiembre.component';
import { DynamicQrComponent } from './components/dynamic-qr/dynamic-qr.component';
import { LealtadComponent } from './views/campaigns/lealtad/lealtad.component';
import { DynamicQrSucursalesComponent } from './components/dynamic-qr-sucursales/dynamic-qr-sucursales.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ContactoComponent,
    VacantesComponent,
    CafeComponent,
    SucursalComponent,
    FacturaComponent,
    BlogComponent,
    ProveedorComponent,
    NavBarComponent,
    FooterComponent,
    MenuComponent,
    OrderDetailsComponent,
    OrderModifiersComponent,
    ModifiersComponent,
    MenuToGoComponent,
    SelectSucursalComponent,
    HistoriaComponent,
    VisionComponent,
    ValoresComponent,
    MisionComponent,
    MiOrdenComponent,
    ProductsCatComponent,
    PromocionesYPaquetesComponent,
    MenuredirectComponent,
    NotFoundComponent,
    EventoComponent,
    QrRestauranteComponent,
    QrDesayunosComponent,
    QrComidasycenasComponent,
    QrToGoComponent,
    QrSdComponent,
    CoberturaADomicilioComponent,
    MenuQrComponent,
    EventoDosComponent,
    WidgetComponent,
    ReservacionesComponent,
    ReservacionOpenTableComponent,
    WidgetResponsiveComponent,
    MexicanisimoComponent,
    CampanaComponent,
    MenuCarbonComponent,
    CuaresmaComponent,
    SeptiembreComponent,
    DynamicQrComponent,
    LealtadComponent,
    DynamicQrSucursalesComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    AngularFontAwesomeModule,
    NgbModule,
    BrowserAnimationsModule, // required animations module
    NgxIziToastModule,
    StoreModule.forRoot({ orden: ordenesReducer, type: typeOrderReducer, modifiers: modifiersReducer, sucursal: sucursalReducer, sucursal2: sucursalReducer2, sucursal3: sucursalReducer3}),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    AgmCoreModule.forRoot({
      apiKey:'AIzaSyAZXtonwkhR49vrdBqxBV3duROmwkUhuVc'
    }),
    AgmDirectionModule,     // agm-direction
    RecaptchaModule,
    PdfViewerModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
