import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dynamic-qr',
  templateUrl: './dynamic-qr.component.html',
  styleUrls: ['./dynamic-qr.component.css']
})
export class DynamicQrComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    const externalUrl = 'https://microsites.spnty.co/madero/';
    window.location.href = externalUrl;
  }

}
