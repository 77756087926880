import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dynamic-qr-sucursales',
  templateUrl: './dynamic-qr-sucursales.component.html',
  styleUrls: ['./dynamic-qr-sucursales.component.css']
})
export class DynamicQrSucursalesComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {

    this.route.params.subscribe( params => {

      if(params['id'])
      {
        const externalUrl = 'https://microsites.spnty.co/madero/register?invite='+params['id'];
        window.location.href = externalUrl;
      }
      else this.router.navigate(['/']);

    });
    
  }

}
