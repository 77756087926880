import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './views/home/home.component';
import { ContactoComponent } from './views/contacto/contacto.component';
import { VacantesComponent } from './views/vacantes/vacantes.component';
import { CafeComponent } from './views/cafe/cafe.component';
import { SucursalComponent } from './views/sucursal/sucursal.component';
import { FacturaComponent } from './views/factura/factura.component';
import { BlogComponent } from './views/blog/blog.component';
import { ProveedorComponent } from './views/proveedor/proveedor.component';

import { MenuComponent } from './views/menu/menu.component';
import { ModifiersComponent } from './views/modifiers/modifiers.component';
import { MenuToGoComponent } from './views/menu-to-go/menu-to-go.component';
import { HistoriaComponent } from './views/historia/historia.component';
import { MisionComponent } from './views/mision/mision.component';
import { VisionComponent } from './views/vision/vision.component';
import { ValoresComponent } from './views/valores/valores.component';
import { MiOrdenComponent } from './views/mi-orden/mi-orden.component';
import { PromocionesYPaquetesComponent } from './views/promociones-y-paquetes/promociones-y-paquetes.component';
import { MenuredirectComponent } from './components/menuredirect/menuredirect.component';
import { NotFoundComponent } from './views/not-found/not-found.component';
import { EventoComponent } from './views/evento/evento.component';
import { QrRestauranteComponent } from './views/qr-restaurante/qr-restaurante.component';
import { QrDesayunosComponent } from './views/qr-desayunos/qr-desayunos.component';
import { QrComidasycenasComponent } from './views/qr-comidasycenas/qr-comidasycenas.component';
import { QrToGoComponent } from './views/qr-to-go/qr-to-go.component';
import { QrSdComponent } from './views/qr-sd/qr-sd.component';
import { CoberturaADomicilioComponent } from './views/cobertura-a-domicilio/cobertura-a-domicilio.component';
import { EventoDosComponent } from './views/evento-dos/evento-dos.component';
import { ReservacionesComponent } from './views/reservaciones/reservaciones.component';

import { MexicanisimoComponent } from './views/mexicanisimo/mexicanisimo.component';
import { CampanaComponent } from './views/campana/campana.component';
import { MenuCarbonComponent } from './views/menu-carbon/menu-carbon.component';

import { CuaresmaComponent } from './views/campaigns/cuaresma/cuaresma.component';
import { SeptiembreComponent } from './views/campaigns/septiembre/septiembre.component';
import { LealtadComponent } from './views/campaigns/lealtad/lealtad.component';

import { DynamicQrComponent } from './components/dynamic-qr/dynamic-qr.component';
import { DynamicQrSucursalesComponent } from './components/dynamic-qr-sucursales/dynamic-qr-sucursales.component';

const routes: Routes = [
  { 
    path: 'campaigns', 
    children: [
      { path: 'cuaresma', component: CuaresmaComponent },
      { path: 'de-chile-mole-y-pozole', component: SeptiembreComponent },
    ] 
  },
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'historia',
    component: HistoriaComponent
  },
  {
    path: 'mision',
    component: MisionComponent
  },
  {
    path: 'vision',
    component: VisionComponent
  },
  {
    path: 'valores',
    component: ValoresComponent
  },
  {
    path: 'blog',
    component: BlogComponent
  },
  {
    path: 'cafeypan',
    component: CafeComponent
  },
  {
    path: 'contacto',
    component: ContactoComponent
  },
  {
    path: 'vacantes',
    component: VacantesComponent
  },

  {
    path: 'sucursales',
    component: SucursalComponent
  },
  // {
  //   path: 'menu/menu-para-papa',
  //   component: CampanaComponent
  // },
  {
    path: 'facturacion',
    component: FacturaComponent
  },

  {
    path: 'proveedores',
    component: ProveedorComponent
  },
  /* {
     path: 'orden',
     component : MiOrdenComponent
   },*/


  /*{
    path: 'menu/restaurante',
    component : MenuComponent
  },*/
  /*  {
      path: 'menu/restaurante/:cat',
      component : MenuComponent
    },*/
  /*  {
      path: 'menu/parallevar',
      component : MenuToGoComponent
    },
    {
      path: 'menu/parallevar/:cat',
      component : MenuToGoComponent
    },
    {
      path: 'menu/promocionesypaquetes',
      component : PromocionesYPaquetesComponent
    },
  
  
    {
      path: 'menu/promocionesypaquetes/:cat',
      component : PromocionesYPaquetesComponent
    },*/
  {
    path: 'modificadores/:id/:product',
    component: ModifiersComponent
  },

  {
    path: 'modificadores/:id/:product',
    component: ModifiersComponent
  },
  // menus qr
  {
    path: 'EnRestaurante',
    redirectTo: 'qr-restaurante'
  },
  // maderorestaurante.mx/qr-restaurante
  {
    path: 'qr-restaurante',
    component: QrRestauranteComponent
  },

  // q's sucursales
  {
    path: 'qr-restaurante-tula',
    component: QrRestauranteComponent
  },
  {
    path: 'qr-restaurante-churubusco',
    component: QrRestauranteComponent
  },

  {
    path: 'qr-restaurante-madero',
    component: QrRestauranteComponent
  },

  {
    path: 'qr-restaurante-sanjavier',
    redirectTo: '/qr-restaurante'
  },
  {
    path: 'qr-restaurante-villas',
    component: QrRestauranteComponent
  },
  {
    path: 'qr-restaurante-irapuato', // Irapuato
    component: QrRestauranteComponent
  },
  {
    path: 'qr-restaurante-queretaro', // Zaragoza
    component: QrRestauranteComponent
  },
  {
    path: 'qr-restaurante-coacalco',
    component: QrRestauranteComponent
  },
  {
    path: 'qr-restaurante-sendero',
    component: QrRestauranteComponent
  },
  {
    path: 'qr-restaurante-sanmarcos',
    component: QrRestauranteComponent
  },
  {
    path: 'qr-restaurante-metepec',
    component: QrRestauranteComponent
  },

  // maderorestaurante.mx/qr-desayunos
  {
    path: 'qr-desayunos',
    component: QrDesayunosComponent
  },
  // maderorestaurante.mx/qr-comidasycenas
  {
    path: 'qr-comidasycenas',
    component: QrComidasycenasComponent
  },
  {
    path: 'menu-carbon',
    component: MenuCarbonComponent
  },
  // maderorestaurante.mx/qr-to-go
  /*{
    path: 'qr-to-go',
    component : QrToGoComponent
  },*/
  // maderorestaurante.mx/qr-sd
  /*{
    path: 'qr-sd',
    component : QrSdComponent
  },*/
  /*{
    path: 'reservaciones',
    component: ReservacionesComponent
  },*/
  /*  {
      path: 'cobertura-a-domicilio',
      component : CoberturaADomicilioComponent
    },*/
  { path: 'lealtad', component: LealtadComponent },
  { path: 'lealtad-qr', component: DynamicQrComponent },
  { path: 'lealtad-qr-sucursal/:id', component: DynamicQrSucursalesComponent },
  { path: '**', component: NotFoundComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
